import * as React from "react"
import "../styles/app.scss"
// @ts-ignore
import Layout from "../components/Layout";
import HomePage from "./home/HomePage";

export default class IndexPage extends React.Component {
    render() {
        return (
            <>
                <Layout>
                    <HomePage/>
                </Layout>
            </>
        )
    }
}
